import { DataTable } from 'components/DataTable';
import Loader from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { useState } from 'react';
import { useCertifiedList } from 'modules/certified-users/hooks/useCertifiedList';
import { columns } from './Column';
import SelectOptions from 'components/SelectOptions';
import { useAllCourseLists } from 'modules/courses/hooks/useAllCourseLists';

const CerfiedUsers = () => {
  const [courseId, setCourseId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: courses } = useAllCourseLists();
  const {
    data: cerfiedUsersList,
    paginationInfo,
    isLoading,
  } = useCertifiedList(currentPage, courseId);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2">
          <SelectOptions
            value={courseId}
            onChange={setCourseId}
            data={courses?.map(course => ({
              label: course.name,
              value: course._id,
            }))}
            placeholder="Kurs nomi"
            className="self-end"
            clearable
          />
          <DataTable
            columns={columns}
            data={cerfiedUsersList}
            navigateTable={false}
          />
          <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          />
        </div>
      )}
    </div>
  );
};

export default CerfiedUsers;
