import { ColumnDef } from '@tanstack/react-table';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { format } from 'date-fns';
import { CertifiedUser } from 'modules/certified-users/types';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { normalizeImgUrl } from 'utils/normalizeImgUrl';

export const columns: ColumnDef<CertifiedUser>[] = [
  {
    accessorKey: '_id',
    header: '',
    cell: () => <span />,
  },
  {
    accessorKey: 'image',
    header: 'Rasm',
    cell: ({ row }) => (
      <Avatar className="size-8">
        <AvatarImage src={normalizeImgUrl(row.getValue('image'))} />
        <AvatarFallback>
          {row.original.profile.first_name.charAt(0)}
        </AvatarFallback>
      </Avatar>
    ),
    size: 50,
  },
  {
    accessorKey: 'full_name',
    header: "O'quvchi ma'lutlari",
    cell: ({ row }) => (
      <div className="flex items-start flex-col gap-px">
        <div>
          {row.original.profile.first_name} {row.original.profile.last_name}
        </div>
        <div className="text-muted-foreground">
          {row.original.profile.address.region},{' '}
          {row.original.profile.address.district},{' '}
          {row.original.profile.address.neighborhood}
        </div>
        <div className="text-muted-foreground">
          {formatPhoneNumber(row.original.phone_number)}
        </div>
      </div>
    ),
  },
  {
    accessorKey: 'name',
    header: 'Kurs nomi',
  },
  {
    accessorKey: 'completed_at',
    header: 'Sertifikat olgan vaqti',
    cell: ({ row }) => (
      <div>
        {format(new Date(row.getValue('completed_at')), 'dd.MM.yyyy, HH:mm')}
      </div>
    ),
  },
];
