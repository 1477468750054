import http from 'services/api';

export const GetCertifiedList = async (
  currentPage: number,
  courseId?: string
) => {
  return await http.get(`/profile/finished-courses`, {
    params: { page: currentPage, course_id: courseId },
  });
};
