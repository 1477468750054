import { CertifiedUser } from './types';

export const certifiedUser = (item?: CertifiedUser) => {
  return {
    _id: item?._id ?? '',
    certificate: item?.certificate ?? '',
    name: item?.name ?? '',
    description: item?.description ?? '',
    phone_number: item?.phone_number ?? '',
    completed_at: item?.completed_at ?? new Date(),
    profile: {
      user: item?.profile?.user ?? '',
      first_name: item?.profile?.first_name ?? '',
      last_name: item?.profile?.last_name ?? '',
      address: {
        country: item?.profile?.address?.country ?? '',
        region: item?.profile?.address?.region ?? '',
        district: item?.profile?.address?.district ?? '',
        neighborhood: item?.profile?.address?.neighborhood ?? '',
      },
      education: {
        type: item?.profile?.education?.type ?? '',
        region: item?.profile?.education?.region ?? '',
        district: item?.profile?.education?.district ?? '',
        organization: item?.profile?.education?.organization ?? '',
      },
      image: item?.profile?.image ?? '',
      occupation: item?.profile?.occupation ?? '',
      points: item?.profile?.points ?? 0,
    },
  };
};

export const certifiedUserList = (data?: CertifiedUser[]) =>
  data?.length ? data.map(item => certifiedUser(item)) : [];
