interface Pagination {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_pages: number;
  total_records: number;
}

export const getPagination = (item?: Pagination) => {
  return {
    current_page: item?.current_page ?? 0,
    next_page: item?.next_page ?? 0,
    prev_page: item?.prev_page ?? 0,
    total_pages: item?.total_pages ?? 0,
    total_records: item?.total_records ?? 0,
  };
};
