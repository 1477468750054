import { cn } from 'utils/styleUtils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

interface IProps {
  value: string | undefined;
  onChange: (state: any) => void;
  data: { label: string; value: string; disabled?: boolean }[];
  placeholder?: string;
  className?: string;
  clearable?: boolean;
}

export default function SelectOptions({
  value,
  onChange,
  data,
  placeholder,
  className,
  clearable,
}: IProps) {
  return (
    <div className={cn(className, 'w-52 relative')}>
      <Select onValueChange={onChange} value={value}>
        <SelectTrigger
          clearable={clearable && !!value}
          onClear={() => onChange('')}
          className="border-slate-200 rounded-md"
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {data.map(item => (
            <SelectItem
              value={item.value}
              key={item.value}
              disabled={item.disabled}
              className="flex items-center justify-between"
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
