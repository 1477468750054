import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getCoursesList } from '../adapters';
import { GetAllCoursesList } from '../api';

export const useAllCourseLists = () => {
  const initialData = {
    data: getCoursesList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['all_courses_list'],
    queryFn: () => GetAllCoursesList(),
    select: data => ({
      data: getCoursesList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
